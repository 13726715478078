/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import "./bgImg-block.scss";
import ScrollVisible from "../scrollVisible/scrollVisible";

interface Props {
  img: string;
  alt?: string;
  aspectRatio?: string;
  asBg?: boolean;
}

const BgImgBlock: React.FC<Props> = (props: Props) => {
  const { img, alt, aspectRatio = "3/2", asBg = true } = props;

  return (
    <ScrollVisible>
      <div
        className={`bgImg-block ${asBg ? "bgImg-block--asBg" : ""}`}
        title={asBg ? alt : undefined}
        style={
          asBg
            ? {
                backgroundImage: `url(${img})`,
                aspectRatio: aspectRatio,
              }
            : {}
        }
      >
        {asBg ? null : <img src={img} alt={alt ? alt : ""} style={{ aspectRatio: aspectRatio }} />}
      </div>
    </ScrollVisible>
  );
};

export default BgImgBlock;

interface PropsYTProps {
  src: string;
  title?: string;
  aspectRatio?: string;
}

export const BgYTBlock: React.FC<PropsYTProps> = (props: PropsYTProps) => {
  const { src, title, aspectRatio = "560/315" } = props;

  return (
    <ScrollVisible>
      <div className={`bgImg-block`}>
        <iframe
          style={{ aspectRatio: aspectRatio, height: "auto", borderRadius: "8px", marginBottom: "-3px" }}
          title={title}
          width="100%"
          src={src}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </div>
    </ScrollVisible>
  );
};
