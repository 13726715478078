import React from "react";
import "./title-block.scss";

interface Props {
  children: React.ReactNode;
  align?: 'left' | 'center' | 'right';
  disableBottomPadding?: boolean;
  size?: 'small' | 'regular' | 'large';
  isUpperCase?: boolean;
}

const TitleBlock: React.FC<Props> = (props: Props) => {
  const { children, align, disableBottomPadding, size, isUpperCase } = props;
  return (
    <div className={`title-block ${size ? size : "regular"} ${align ? align : "center"} ${isUpperCase ? "uppercase" : ""} ${disableBottomPadding ? "disableBottomPadding" : ""}`}>
      {children}
    </div >
  )
}

export default TitleBlock;