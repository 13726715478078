export class RoutePathPL {
  static RULES: string = "/warunki-uzytkowania";
  static OFIRMIE: string = "/o-firmie";
  static WSPOLPRACA: string = "/wspolpraca";
  static MALOWANIE_PROSZKOWE: string = "/malowanie-proszkowe";
  static KONTAKT: string = "/kontakt";
  static PRODUKTY: string = "/produkty";
  static TECHNOLOGIA: string = "/technologia";
  static HOME: string = "/";
}

export class RoutePathEN {
  static RULES: string = "/en/terms-of-use";
  static OFIRMIE: string = "/en/about-company";
  static WSPOLPRACA: string = "/en/cooperation";
  static MALOWANIE_PROSZKOWE: string = "/en/powder-painting";
  static KONTAKT: string = "/en/contact";
  static PRODUKTY: string = "/en/products";
  static TECHNOLOGIA: string = "/en/technology";
  static HOME: string = "/en";
}

export class RoutePathDE {
  static RULES: string = "/de/nutzungsbedingungen";
  static OFIRMIE: string = "/de/ueber das Unternehmen";
  static WSPOLPRACA: string = "/de/Zusammenarbeit";
  static MALOWANIE_PROSZKOWE: string = "/de/pulverbeschichtungsanlage";
  static KONTAKT: string = "/de/kontakt";
  static PRODUKTY: string = "/de/produkty";
  static TECHNOLOGIA: string = "/de/Technologie";
  static HOME: string = "/de";
}

export class RoutePath {
  static PL = RoutePathPL;
  static EN = RoutePathEN;
  static DE = RoutePathDE;
}