import React from "react";
import "./prefooter.scss";
import { RoutePath } from "../../../route-paths";
import TopLink from "../../../app/components/top-link/top-link";
import { Container } from "codeebo-react-grid";
import { selectLang } from "../../../app/components/lang-switch/langSlice";
import { useAppSelector } from "../../../redux/store";
import T from "../../../app/components/lang-switch/t";

interface Props {}

const PreFooter: React.FC<Props> = (props: Props) => {
  const lang = useAppSelector(selectLang);
  return (
    <>
      <section className="prefooter">
        <Container>
          <div className="rowInfo">
            <div className="footerNav">
              <p className="pb-3">
                <TopLink to={RoutePath[lang].HOME}>
                  <img src="/images/logo.svg" alt="Logo" />
                </TopLink>
              </p>
              <p>Log-Tech Konstrukcje Sp. z o.o. Sp. K</p>
              <p>ul. Strefowa 1 </p>
              <p>56-500 Syców</p>

              {/* <TopLink to={RoutePath[lang].HOME}>
                <p>Polityka prywatności</p>
              </TopLink>
              <TopLink to={RoutePath[lang].RULES}>
                <p>Cookies</p>
              </TopLink>
              <TopLink to={RoutePath[lang].RULES}>
                <p>RODO</p>
              </TopLink> */}
            </div>
            <div className="footerNav pt-md-4 pt-2">
              <TopLink to={RoutePath[lang].OFIRMIE}>
                <p>{T(["O Firmie", "About Company", "Über das Unternehmen"])}</p>
              </TopLink>
              <TopLink to={RoutePath[lang].MALOWANIE_PROSZKOWE}>
                <p>{T(["Malarnia Proszkowa", "Powder Painting", "Pulverbeschichtungsanlage"])}</p>
              </TopLink>
              <TopLink to={RoutePath[lang].TECHNOLOGIA}>
                <p>{T(["Technologia", "Technology", "Technologie"])}</p>
              </TopLink>
              <TopLink to={RoutePath[lang].WSPOLPRACA}>
                <p>{T(["Współpraca", "Cooperation", "Zusammenarbeit"])}</p>
              </TopLink>
              <TopLink to={RoutePath[lang].KONTAKT}>
                <p>{T(["Kontakt", "Contact", "Kontakt"])}</p>
              </TopLink>
            </div>
            <div className="logos">
              <img src="/images/logtech-rectangle.jpg" alt="logo" />
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default PreFooter;
