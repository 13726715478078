import "./cookie-policy-popup.scss";
import { RoutePath } from "../../../route-paths";
import { Link } from "react-router-dom";
import { useCookiePolicyPopup } from "./cookiePolicyPopupSlice";
import { selectLang } from "../../../app/components/lang-switch/langSlice";
import { useAppSelector } from "../../../redux/store";

const CookiePolicyPopup = () => {
  const lang = useAppSelector(selectLang);
  const { setOpen, isOpen } = useCookiePolicyPopup();
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      {isOpen ? (
        <div className="cookies-info-box">
          <div className="left-side">
            <h3>Polityka Prywatności i Cookies</h3>
            <p>
              Używamy technologi plików cookies, aby analizować ruch na naszej stronie. Przeglądając stronę, akceptujesz warunki użytkowania i&nbsp;politykę
              prywatności. W&nbsp;każdej chwili możesz zmienić zdanie i&nbsp;wyłączyć pliki cookies w przeglądarce.
            </p>
          </div>
          <div className="right-side">
            <div className="btn-material-nav" onClick={handleCancel}>
              Akceptuję
            </div>
            <Link to={RoutePath[lang].RULES}>
              <div className="privacy">Szczegóły</div>
            </Link>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CookiePolicyPopup;
