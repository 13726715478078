import React from "react";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import StandardBlock from "../../components/standard-block/standard-block";
import TitleBlock from "../../components/title-block/title-block";
// import LogoImg from "../../assets/logo.png";
import { Helmet } from "react-helmet-async";
import MainHeader from "../../components/main-header/main-header";
import "./malowanie-proszkowe.scss";
import { Col, Container, Grid, Row } from "codeebo-react-grid";
import BgImgBlock from "../../components/bgImg-block/bgImg-block";
import TextBlock from "../../../libs/text-block/text-block";
import T from "../../../app/components/lang-switch/t";

const MalowanieProszkowePage = () => {
  return (
    <PageWrapper className="malowanie-proszkowe">
      <Helmet>
        <title>{"LOG-TECH"}</title>
      </Helmet>
      <section>
        <Container>
          <MainHeader align="left">
            {T(["Malowanie proszkowe metali w firmie LOG-TECH", "Metal powder painting at Log-Tech ", "Pulverbeschichtung von Metallen bei Log-Tech "])}
          </MainHeader>

          <Grid>
            <Row>
              <Col size={6}>
                <StandardBlock>
                  <br />
                  <TitleBlock>
                    {T(["KONTROLA JAKOŚCI DOSTARCZANYCH TOWARÓW", "QUALITY CONTROL OF THE GOODS SUPPLIED", "QUALITÄTSKONTROLLE DER GELIEFERTEN WAREN"])}
                  </TitleBlock>
                  <br />
                  <br />
                  <p className="paddingRight">
                    {T([
                      "Na każdym etapie produkcyjnym, monitorujemy wszystkie procesy mające wpływ na proces lakierowania. Mycie wstępne.W każdej z komór stale kontrolujemy temperaturę, konduktywność oraz pH kąpieli. System automatycznego dozowania pozwala skuteczniej utrzymywać na wysokim poziomie jakość wszystkich kąpieli. Piec suszenia w którym monitorujemy czas oraz temperaturę. Optymalne parametry, czas oraz temperatura wygrzewania zapewniają skuteczny proces nakładania farby w kabinach lakierniczych. Kabina Lakiernicza Automatyczna to szybki i powtarzalny proces malowania. Odpowiednie ustawienia zapewniają powtarzalność malowanych powierzchni. Kabina Lakiernicza ręczna pozwala na pomalowanie, złożonych i trudno dostępnych powierzchni. Wysokie umiejętności naszych lakierników pozwalają pomalować praktycznie każdy dostarczony do malarni detal. Piec utwardzania - Dobrze dobrane parametry pozwalają zoptymalizować proces polimeryzacji farby, zapewnić jej skuteczne wiązanie oraz adhezję. Optymalny czas polimeryzacji zawsze odbywa się za pomocą urządzenia typu Loger monitorującego rozkład temperatury w piecu. Dokładna analiza danych z rejestratora pozwala optymalnie dobrać parametry w procesie polimeryzacji.",
                      "Pre-washing.  We regularly monitor the bath’s temperature, conductivity and pH in each of the chambers. The auto-dosing system means that we can keep high quality of all baths in a more efficient fashion. Drying oven to monitor the time and temperature. Optimum heating parameters, time and temperature ensure efficient paint application in powder coating booths Automatic Powder Coating Booth means fast and replicable painting process. Proper settings ensure replicability of the surfaces being painted. Manual Powder Coating Booth makes it possible to paint complex and difficult-to-reach surfaces. Thanks to great skills of our powder coat painters, we can paint almost every piece delivered to our paint shop. Curing oven Carefully selected parameters optimise the paint polymerisation process as well as ensure its effective bonding and adhesion. The optimum polymerisation time is always measured using a Logger-type device which monitors the temperature distribution in the oven. Careful logger data analysis helps us to optimise the polymerisation process parameters.",
                      "In jeder Phase der Produktion überwachen wir alle Prozesse, die den Lackiervorgang beeinflussen. Vorwaschen. In jeder der Kammern überwachen wir ständig die Temperatur, die Konduktivität und den pH-Wert des Bades. Das automatische Dosiersystem ermöglicht eine effektivere Aufrechterhaltung der hohen Qualität aller Bäder. Trocknungsofen in dem wir Zeit und Temperatur überwachen. Optimale Parameter, Ausglühzeit und -temperatur gewährleisten einen effizienten Farbauftrag in Lackierkabinen. Automatische Lackierkabine bedeutet ein schnelles und wiederholbares Lackierverfahren. Entsprechende Einstellungen gewährleisten die Wiederholbarkeit von lackierten Oberflächen. Die manuelle Lackierkabine ermöglicht das Lackieren komplexer und schwer zugänglicher Oberflächen. Dank der hohen Qualifikation unserer Lackierer können wir praktisch jedes in die Lackiererei gelieferte Werkstück lackieren. Aushärteofen - Gut gewählte Parameter ermöglichen eine Optimierung des Polymerisationsprozesses des Lacks und gewährleisten eine wirksame Bindung und Adhäsion. Die optimale Polymerisationszeit erfolgt immer mit Hilfe des Logger-Geräts, das die Temperaturverteilung im Ofen überwacht. Die sorgfältige Analyse der Daten des Schreibers ermöglicht eine optimale Auswahl der Parameter im Polymerisationsprozess.",
                    ])}
                  </p>
                </StandardBlock>
              </Col>
              <Col size={6} className="py-md-2 py-3">
                <BgImgBlock aspectRatio="3/2" img={"/images/malarnia/1.png"} />
              </Col>
            </Row>
          </Grid>
        </Container>
      </section>

      <section className="lightGrayBg py-md-5 py-3">
        <Container>
          <Grid>
            <Row>
              <Col size={6}>
                <StandardBlock>
                  <br />
                  <TitleBlock>{T(["OBRÓBKA ŚCIERNA", "Abrasive machining ", "Abrasive Bearbeitung "])}</TitleBlock>
                  <br />
                  <br />
                  {/* <p className="paddingRight">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                    veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                    voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p> */}
                </StandardBlock>
              </Col>
              <Col size={6} className="py-md-2 py-3">
                <BgImgBlock aspectRatio="3/2" img="/images/malarnia/6.jpg" />
              </Col>
            </Row>
          </Grid>
        </Container>
      </section>
      <section className="py-md-5 py-3">
        <Container>
          <Grid>
            <Row>
              <Col size={6}>
                <StandardBlock>
                  <br />
                  <TitleBlock>{T(["OBRÓBKA CHEMICZNA", "CHEMICAL TREATMENT", "CHEMISCHE BEHANDLUNG"])}</TitleBlock>
                  <br />
                  <br />
                  <p className="paddingRight">
                    {T([
                      "Podstawą długotrwałej ochrony antykorozyjnej jest chemiczna obróbka powierzchni, która odbywa się w sześciu strefach. W procesie natryskiwania otrzymuje optymalne podłoże adhezyjne z konwersją o podniesionym stopniu antykorozyjnym do późniejszego powlekania. Ciągłe monitorowanie i automatyczna kontrola istotnych danych procesowych zapewnia optymalną obróbkę wstępną, a tym samym gwarantuje wysoką jakość powłoki.",
                      "We achieve durable anti-corrosion protection thanks to chemical surface treatment which takes place in six zones. During the spraying process, we get an optimum adhesive substrate with an increased anti-corrosion conversion for further coating. Ongoing monitoring and automatic control of relevant process data ensure optimum pre-treatment and in consequence guarantee high quality coating.",
                      "Die Grundlage für den langfristigen Korrosionsschutz ist die chemische Oberflächenbehandlung, die in sechs Zonen erfolgt. Im Spritzverfahren erhält es eine optimale Haftgrundlage mit Konvertierung mit erhöhtem Korrosionsschutz für die anschließende Beschichtung. Die ständige Überwachung und automatische Kontrolle der relevanten Prozessdaten sorgt für eine optimale Vorbehandlung und garantiert damit eine hohe Beschichtungsqualität.",
                    ])}
                  </p>
                </StandardBlock>
              </Col>
              <Col size={6} className="py-md-2 py-3">
                <BgImgBlock aspectRatio="3/2" img="/images/malarnia/2.jpg" />
              </Col>
            </Row>
          </Grid>
        </Container>
      </section>
      <section className="py-md-5 py-3">
        <Container>
          <Row className="red-tile-section py-0">
            <Col size={[6, 6, 6, 12]} className="py-5">
              <TextBlock className="p-5">
                <TitleBlock>
                  {T([
                    "MALOWANIE PROSZKIEM W PROCESIE POWLEKANIA ZA POMOCĄ PISTOLETÓW ELEKTROSTATYCZNYCH",
                    "POWDER PAINTING IN COATING PROCESS WITH ELECTROSTATIC GUNS",
                    "PULVERBESCHICHTUNG IM BESCHICHTUNGSVERFAHREN MIT ELEKTROSTATISCHEN PISTOLEN",
                  ])}
                </TitleBlock>
                <br />
                <br />
                <p className="paddingRight">
                  {T([
                    "Pistolety elektrostatyczne generują chmurę proszku, naładowaną elektrycznie pod wysokim napięciem. Cząstki proszku przylegają do powierzchni obrabianego przedmiotu tworząc warstwę farby proszkowej. Malarnia proszkowa Log-Tech posiada w użyciu dwie nowoczesne kabiny do malowania proszkowego.",
                    "Electrostatic guns generate a cloud of powder, electro-charged at high voltage. Powder particles adhere to the workpiece surface, forming a layer of powder paint. Log-Tech's powder paint shop has two advanced powder painting booths in use.",
                    "Elektrostatische Pistolen erzeugen eine Wolke aus Pulver, das unter Hochspannung elektrisch aufgeladen wurde. Die Pulverpartikel haften an der Oberfläche des Werkstücks und bilden eine Pulverlackschicht. In der Log-Tech- Pulverbeschichtungsanlage sind zwei moderne Pulverbeschichtungskabinen im Einsatz.",
                  ])}
                </p>
              </TextBlock>
            </Col>
            <Col size={[6, 6, 6, 12]} className="bg-col" style={{ backgroundImage: `url(${"/images/malarnia/3.jpg"})` }} />
          </Row>
        </Container>
      </section>
      <section className="py-md-5 py-3">
        <Container>
          <Grid>
            <Row>
              <Col size={6}>
                <StandardBlock>
                  <br />
                  <TitleBlock>{T(["PROCES WYPALANIA (POLIMERYZACJI)", "HEATING PROCESS (POLYMERISATION)", "AUSGLÜHVERFAHREN (POLYMERISATION)"])}</TitleBlock>
                  <br />
                  <br />
                  <p className="paddingRight">
                    {T([
                      "Stosowany jest w celu usieciowienia powłoki proszkowej. Obrabiane przedmioty sa pogrzewane do ok. 160 - 200°C w piecu wypalania. Tworzy to odporną warstwę farby o równomiernym rozlaniu.",
                      "It is used to cross-link the powder coating. Workpieces are heated to approximately 160-200°C in the curing oven. This gives a durable layer of paint with even coating.",
                      "Dieses Verfahren dient der Vernetzung der Pulverbeschichtung. Die Werkstücke werden in einem Ausglühofen auf ca. 160 - 200°C erhitzt. Dadurch entsteht ein widerstandsfähiger Lackfilm mit gleichmäßiger Verteilung",
                    ])}
                  </p>
                </StandardBlock>
              </Col>
              <Col size={6} className="py-md-2 py-3">
                <BgImgBlock aspectRatio="3/2" img="/images/malarnia/logtech-wypalanie.png" />
              </Col>
            </Row>
          </Grid>
        </Container>
      </section>
      <section className="lightGrayBg py-md-5 py-3">
        <Container>
          <Grid>
            <Row>
              <Col size={6}>
                <StandardBlock>
                  <br />
                  <TitleBlock>
                    {T(["KONTROLA JAKOŚCI GOTOWEGO PRODUKTU", "FINISHED PRODUCT QUALITY CONTROL", "QUALITÄTSKONTROLLE DES FERTIGEN PRODUKTS"])}
                  </TitleBlock>
                  <br />
                  <br />
                  <p className="paddingRight">
                    {T([
                      "Po zakończeniu całego procesu malowania pracownicy dokładnie oglądają pomalowany detal i dokonują oceny wstępnej. Dodatkowo jakość powołoki potwierdzamy badaniami przeprowadzając szereg testów, m.in.. grubości powłoki, przyczepności.",
                      "Once the entire painting process has been completed, our specialists carefully inspect the painted piece and make an initial check. In addition to this, we confirm the coating’s quality by running a series of tests, including coating thickness test, adhesion test, etc.",
                      "Nach Abschluss des gesamten Lackiervorgangs prüfen die Mitarbeiter das lackierte Werkstück genau und nehmen eine erste Begutachtung vor. Darüber hinaus bestätigen wir die Qualität der Beschichtung durch eine Reihe von Prüfungen, wie z.B. der Schichtdicke, der Haftfestigkeit.",
                    ])}
                  </p>
                </StandardBlock>
              </Col>
              <Col size={6} className="py-md-2 py-3">
                <BgImgBlock aspectRatio="3/2" img="/images/malarnia/logtech-kontrola-jakosci.png" />
              </Col>
            </Row>
          </Grid>
        </Container>
      </section>
      <section className="py-md-5 py-3">
        <Container>
          <Grid>
            <Row>
              <Col size={6}>
                <StandardBlock>
                  <br />
                  <TitleBlock>{T(["PAKOWANIE I WYSYŁKA", "PACKING AND SHIPPING", "VERPACKUNG UND VERSAND"])}</TitleBlock>
                  <br />
                  <br />
                  <p className="paddingRight">
                    {T([
                      "Zawsze z uwzględnieniem indywidualnych potrzeb klienta.",
                      "Always taking into account the individual client needs.",
                      "Dabei werden die individuellen Bedürfnisse des Kunden stets berücksichtigt.",
                    ])}
                  </p>
                </StandardBlock>
              </Col>
              <Col size={6} className="py-md-2 py-3">
                <BgImgBlock aspectRatio="3/2" img="/images/malarnia/logtech-pakowanie-wysylka.png" />
              </Col>
            </Row>
          </Grid>
        </Container>
      </section>
    </PageWrapper>
  );
};

export default MalowanieProszkowePage;
