import React from "react";
import "./big-num-block.scss";

interface Props {
  children?: React.ReactNode;
  align?: 'left' | 'center' | 'right';
}

const BigNumBlock: React.FC<Props> = (props: Props) => {
  const { children, align } = props;
  return (
    <div className={`big-num-block ${align ? align : "center"}`}>
      {children}
    </div >
  )
}

export default BigNumBlock;