import React from "react";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import StandardBlock from "../../components/standard-block/standard-block";
import TitleBlock from "../../components/title-block/title-block";
// import LogoImg from "../../assets/logo.png";
import "./technologia.scss";
import { Helmet } from "react-helmet-async";
import MainHeader from "../../components/main-header/main-header";
import TopLink from "../../../app/components/top-link/top-link";
import Button from "../../../libs/buttons/Button/Button";
import { RoutePath } from "../../../route-paths";
import { Col, Container, Grid, Row } from "codeebo-react-grid";
import BgImgBlock from "../../components/bgImg-block/bgImg-block";
import { selectLang } from "../../../app/components/lang-switch/langSlice";
import { useAppSelector } from "../../../redux/store";
import T from "../../../app/components/lang-switch/t";

const TechnologiaPage = () => {
  const lang = useAppSelector(selectLang);
  return (
    <PageWrapper className="technologia">
      <Helmet>
        <title>{"LOG-TECH"}</title>
      </Helmet>
      <section>
        <Container>
          <MainHeader align="left">{T(["Nowoczesna malarnia proszkowa", "Advanced Powder Paint Shop", "Moderne Pulverbeschichtungsanlage"])}</MainHeader>

          <Grid>
            <Row>
              <Col size={6}>
                <StandardBlock>
                  <br />
                  <TitleBlock>{T(["AUTOMATYCZNA MALARNIA PROSZKOWA", "AUTOMATIC POWDER PAINT SHOP", "AUTOMATISCHE PULVERBESCHICHTUNGSANLAGE"])}</TitleBlock>
                  <br />
                  <br />
                  <p className="paddingRight">
                    <p>{T(["Długość: 5000 mm", "Length: 5000 mm", "Länge: 5000 mm"])}</p>
                    <p>{T(["Szerokość:1500 mm", "Width:1500 mm", "Breite:1500 mm"])}</p>
                    <p>{T(["Wysokość: 1800 mm", "Height: 1800 mm", "Höhe: 1800 mm"])}</p>
                    <p>{T(["Waga: 500kg", "Weight: 500 kg", "Gewicht 500 kg"])}</p>
                  </p>
                </StandardBlock>
                <br />
                <br />
                {/* <TopLink to={RoutePath[lang].MALOWANIE_PROSZKOWE}>
                  <Button variant="outline">Dowiedz się więcej</Button>
                </TopLink> */}
              </Col>
              <Col size={6} className="py-md-2 py-3">
                <BgImgBlock aspectRatio="3/2" img={"/images/malarnia-automatyczna-logtech.png"} />
              </Col>
            </Row>
          </Grid>
        </Container>
      </section>

      <section className="lightGrayBg py-md-5 py-3">
        <Container>
          <Grid>
            <Row>
              <Col size={6}>
                <StandardBlock>
                  <br />
                  <TitleBlock>{T(["MANUALNA MALARNIA PROSZKOWA", "MANUAL POWDER PAINT SHOP", "MANUELLE PULVERBESCHICHTUNGSANLAGE"])}</TitleBlock>
                  <br />
                  <br />
                  <p className="paddingRight">
                    <p>{T(["Długość: 5000 mm", "Length: 5000 mm", "Länge: 5000 mm"])}</p>
                    <p>{T(["Szerokość:1500 mm", "Width:1500 mm", "Breite:1500 mm"])}</p>
                    <p>{T(["Wysokość: 1800 mm", "Height: 1800 mm", "Höhe: 1800 mm"])}</p>
                    <p>{T(["Waga: 500kg", "Weight: 500 kg", "Gewicht 500 kg"])}</p>
                  </p>
                </StandardBlock>
              </Col>
              <Col size={6} className="py-md-2 py-3">
                <BgImgBlock aspectRatio="3/2" img="/images/mlarnia-manualna-logtech.png" />
              </Col>
            </Row>
          </Grid>
        </Container>
      </section>
      <section className="primaryBg darkGrayBg py-md-5 py-3">
        <Container>
          <Grid>
            <Row>
              <Col size={10} offset={1}>
                <StandardBlock>
                  <TitleBlock>
                    {T([
                      "Najnowsza technologia malowania proszkowego niesie ze sobą wiele zalet, takich jak:",
                      "Latest powder painting technology offers a number of advantages, such as:",
                      "Die neueste Pulverbeschichtungstechnologie bringt viele Vorteile mit sich, wie zum Beispiel:",
                    ])}
                  </TitleBlock>
                  <br />
                  <br />
                  <p className="paddingRight">
                    <ul style={{ textAlign: "left" }}>
                      <li>
                        {T([
                          "wysoka odporność na uszkodzenia mechaniczne elementów malowanych",
                          "high resistance to mechanical damage of the pieces being painted",
                          "hohe Beständigkeit der lackierten Werkstücke gegen mechanische Beschädigung",
                        ])}
                      </li>
                      <li>
                        {T([
                          "doskonała ochrona przed korozją (C3, C4, C5)",
                          "excellent anti-corrosion protection (C3, C4, C5)",
                          "ausgezeichneten Korrosionsschutz (C3, C4, C5)",
                        ])}
                      </li>
                      <li>
                        {T([
                          "optymalizacja kosztów dzięki w pełni zautomatyzowanym procesom",
                          "cost optimisation thanks to fully automated processes ",
                          "Kostenoptimierung durch vollautomatische Prozesse",
                        ])}
                      </li>
                      <li>{T(["krótkie terminy realizacji", "short lead times", "kurze Fertigungszeiten"])}</li>
                      <li>
                        {T([
                          "powłoka przyjazna dla środowiska - bez użycia rozpuszczalników!",
                          "eco-friendly coating – no solvents used!",
                          "umweltfreundliche Beschichtung: ohne Einsatz von Lösungsmitteln!",
                        ])}
                      </li>
                      <li>{T(["szeroka gama kolorów do wyboru", "wide range of colours to choose from", "eine breite Palette von Farben zur Auswahl"])}</li>
                      <li>{T(["różnorodne struktury powłok", "various coating structures", "verschiedene Beschichtungsstrukturen"])}</li>
                      <li>{T(["estetyczny wygląd", "nice-looking finish", "ästhetisches Aussehen"])}</li>
                    </ul>
                  </p>
                </StandardBlock>
              </Col>
            </Row>
          </Grid>
        </Container>
      </section>

      <section className="primaryBg">
        <Container>
          <Grid>
            <Row className="py-3">
              <Col size={8} className="py-2">
                <StandardBlock>
                  <TitleBlock>
                    {T([
                      "Rozpocznij współpracę z naszą Malarnią Proszkową",
                      "MWe encourage you to partner with our Powder Paint Shop ",
                      "Beginnen Sie eine Zusammenarbeit mit unserer Pulverbeschichtungsanlage ",
                    ])}
                  </TitleBlock>
                </StandardBlock>
              </Col>
              <Col size={4} className="py-2">
                <TopLink to={RoutePath[lang].KONTAKT}>
                  <Button variant="light">
                    {T(["Skontaktuj się", "Feel free to contact us ", "Kontaktieren Sie uns "])}
                  </Button>
                </TopLink>
              </Col>
            </Row>
          </Grid>
        </Container>
      </section>
    </PageWrapper>
  );
};

export default TechnologiaPage;
