import React, { useState, useEffect, useRef } from "react";
import "./dropdown.scss";

interface Option {
  link?: string;
  label: string;
  id: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

interface DropdownProps {
  onChange: (selected: Option) => void;
  options: Option[];
  initValue?: Option;
}

const Dropdown: React.FC<DropdownProps> = ({ onChange, options, initValue }) => {
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(initValue);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (initValue) {
      setSelectedOption(initValue);
    }
  }, [initValue]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (option: Option, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setSelectedOption(option);
    onChange(option);
    setIsOpen(false);
    if (option.onClick) {
      option.onClick(e);
    }
  };

  return (
    <div className="dropdown" ref={dropdownRef}>
      <button
        className="dropdown-btn"
        aria-haspopup="true"
        aria-expanded={isOpen}
        onClick={(e) => {
          setIsOpen(!isOpen);
        }}
      >
        {selectedOption ? selectedOption.label : "..."}
        <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="m16.843 10.211c.108-.141.157-.3.157-.456 0-.389-.306-.755-.749-.755h-8.501c-.445 0-.75.367-.75.755 0 .157.05.316.159.457 1.203 1.554 3.252 4.199 4.258 5.498.142.184.36.29.592.29.23 0 .449-.107.591-.291 1.002-1.299 3.044-3.945 4.243-5.498z" />
        </svg>
      </button>
      {isOpen && (
        <div className="dropdown-content" aria-label="submenu">
          {options.map((option) => (
            selectedOption?.id === option.id ? null : <a
              key={option.id}
              href={option.link}
              onClick={(e) => {
                if (option.onClick) option.onClick(e);
                handleSelect(option, e);
              }}
              tabIndex={0}
              className="option"
            >
              {option.label}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
