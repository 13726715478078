import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, useAppSelector } from "../../../redux/store";
import { useDispatch } from "react-redux";

export interface NavbarState {
  is_navbarActive: Boolean;
  is_navbarOpen: Boolean;
}

const initialState: NavbarState = {
  is_navbarActive: false,
  is_navbarOpen: false,
};

export const navbarSlice = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    set_is_navbarActive: (state, action: PayloadAction<boolean>) => {
      state.is_navbarActive = action.payload;
    },
    set_is_navbarOpen: (state, action: PayloadAction<boolean>) => {
      state.is_navbarOpen = action.payload;
    },
  },
});

export const { set_is_navbarOpen, set_is_navbarActive } = navbarSlice.actions;
export default navbarSlice;

export function useNavbar() {
  const isActive = useAppSelector((state: RootState) => state.navbar.is_navbarActive);
  const isOpen = useAppSelector((state: RootState) => state.navbar.is_navbarOpen);

  const dispatch = useDispatch();

  const setActive = (isActive: boolean) => dispatch<any>(navbarSlice.actions.set_is_navbarActive(isActive));
  const setOpen = (isOpen: boolean) => dispatch<any>(navbarSlice.actions.set_is_navbarOpen(isOpen));


  return { setActive, setOpen, isActive, isOpen };
}

