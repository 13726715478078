import React from "react";
import "./italic-belt-block.scss";
import { Container } from "codeebo-react-grid";

interface Props {
  children: React.ReactNode;
  align?: 'left' | 'center' | 'right';
  padding?: 64 | 128 | 256;
  bgCol?: 'white' | 'light-gray';
  bg?: string;
}

const ItalicBeltBlock: React.FC<Props> = (props: Props) => {
  const { children, align, padding, bgCol, bg } = props;
  return (
    <div className={`italic-belt-block ${align ? align : "center"} padding-${padding ? padding : 128} ${bgCol ? bgCol : 'white'}`} style={{ backgroundImage: `url('${bg}')` }}>
      <Container>
        {children}
      </Container>
    </div >
  )
}

export default ItalicBeltBlock;