import React from "react";
import "./standard-block.scss";

interface Props {
  children: React.ReactNode,
}

const StandardBlock: React.FC<Props> = (props: Props) => {
  const { children } = props;

  return (
    <div className={"standard-block"}>
      {children}
    </div>
  )
}

export default StandardBlock;
