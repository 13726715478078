import React from "react";
import "./page-wrapper.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
}

const PageWrapper: React.FC<Props> = (props: Props) => {
  const { children, className } = props;
  return (
    <div className={`page-wrapper ${className ? className : ""}`} >
      {children}
    </div >
  )
}

export default PageWrapper;