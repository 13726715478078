import React, { useEffect, useRef } from 'react';

interface ScrollVisibleProps {
  offset?: number;
  persistent?: boolean;
  children: React.ReactNode;
}

const ScrollVisible: React.FC<ScrollVisibleProps> = ({
  offset = 0,
  persistent = false,
  children
}) => {
  const childrenRefs = useRef<HTMLElement[]>([]);

  const handleScroll = () => {
    childrenRefs.current.forEach(childRef => {
      if (childRef) {
        const rect = childRef.getBoundingClientRect();
        const isFullyVisible =
          rect.top >= 0 &&
          rect.bottom <= window.innerHeight - offset;

        if (isFullyVisible) {
          childRef.classList.add('scrollVisibleElement');
        } else {
          if (!persistent) {
            childRef.classList.remove('scrollVisibleElement');
          }
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, persistent]);

  useEffect(() => {
    handleScroll();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <>
      {React.Children.map(children, (child, index) => {
        return React.cloneElement(child as React.ReactElement, {
          ref: (el: HTMLElement) => (childrenRefs.current[index] = el)
        });
      })}
    </>
  );
};

export default ScrollVisible;
