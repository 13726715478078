import React, { } from "react";
import "./map-section.scss";
import { useNavbar } from "../navbar/navbarSlice";

interface Props {
  maxWidth?: number
}

const FullMapSection: React.FC<Props> = (props: Props) => {
  const { maxWidth } = props;
  const { isOpen } = useNavbar();
  return (
    <section className="map-section" style={{ maxWidth: maxWidth ? maxWidth : '100%', pointerEvents: isOpen ? "none" : "initial" }}>
      <iframe className="map" title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9979.005199419318!2d17.708464014233485!3d51.297300653228355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47100652943d19cf%3A0x451e01684ff0b73!2sLOG-TECH%20Sp.%20z%20o.o.!5e0!3m2!1spl!2spl!4v1685057314591!5m2!1spl!2spl" frameBorder="0" style={{ border: 0 }} allowFullScreen={false} aria-hidden="false" tabIndex={0}></iframe>
    </section>
  )
}

export default FullMapSection;