import "./home-page.scss";
import "./../../../swiperWrapper.scss";

import { Helmet } from "react-helmet-async";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import TopLink from "../../../app/components/top-link/top-link";
import Button from "../../../libs/buttons/Button/Button";
import { useAppSelector } from "../../../redux/store";
import { selectLang } from "../../../app/components/lang-switch/langSlice";
import { RoutePath } from "../../../route-paths";
import T from "../../../app/components/lang-switch/t";

const BgA = "/images/slide_1.png";
const BgB = "/images/logtech-malarnia-proszkowa-sycow.png";
const BgC = "/images/lakiernia-proszkowa-logtech.png";
const BgD = "/images/logtech-malowanie-proszkowe-automatyczne.png";

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

const HomePage = () => {
  const lang = useAppSelector(selectLang);
  return (
    <PageWrapper className="home-page">
      <Helmet>
        <title>{"LogTech"}</title>
      </Helmet>
      {/* <Container> */}
      <div className="swiperWrapper">
        <Swiper
          spaceBetween={16}
          slidesPerView={1}
          navigation={false}
          autoplay={{
            delay: 4000,
          }}
          pagination={{
            clickable: true,
            renderBullet: function (index, className) {
              return '<span class="' + className + '">' + (index + 1) + "</span>";
            },
          }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
        >
          <SwiperSlide>
            <div className={"sliderBg"} style={{ backgroundImage: `url("${BgA}")` }}>
              <div className={"sliderContent"}>
                <h2 className={"title"}>{T(["LOG-TECH Konstrukcje", "LOG-TECH Constructions", "LOG-TECH Konstructionen"])}</h2>
                <h3 className={"description"}>{T(["Nowoczesna Malarnia Proszkowa", "A modern powder coating shop", "Moderne powdere der malaren"])} </h3>
                <div className=" d-flex pt-3">
                  <TopLink to={RoutePath[lang].MALOWANIE_PROSZKOWE} className="mx-auto">
                    <Button variant="light">{T(["O malarni", "About coating shop", "Was is malaren"])}</Button>
                  </TopLink>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={"sliderBg"} style={{ backgroundImage: `url("${BgB}")` }}>
              <div className={"sliderContent"}>
                <h2 className={"title"}>{T(["Nowoczesna Malarnia Proszkowa", "Modern Powder Painting Shop", "Moderne Pulverlackiererei"])}</h2>
                <h3 className={"description"}>{T(["Nowoczesna Malarnia Proszkowa", "Modern Powder Painting Shop", "Moderne Pulverlackiererei"])}</h3>
                <div className=" d-flex pt-3">
                  <TopLink to={RoutePath[lang].OFIRMIE} className="mx-auto">
                    <Button variant="light">{T(["O firmie", "About us", "Über das Unternehmen"])}</Button>
                  </TopLink>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={"sliderBg"} style={{ backgroundImage: `url("${BgC}")` }}>
              <div className={"sliderContent"}>
                <h2 className={"title"}>{T(["LOG-TECH Konstrukcje", "LOG-TECH Konstrukcje", "LOG-TECH Konstrukcje"])}</h2>
                <h3 className={"description"}>
                  {T([
                    "Automatyczna i Manualna Malarnia Proszkowa",
                    "Automatic and Manual Powder Painting Shop",
                    "Automatische und manuelle Pulverlackiererei",
                  ])}
                </h3>
                <div className=" d-flex pt-3">
                  <TopLink to={RoutePath[lang].TECHNOLOGIA} className="mx-auto">
                    <Button variant="light">{T(["Technologia", "Technology", "Technologie"])}</Button>
                  </TopLink>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={"sliderBg"} style={{ backgroundImage: `url("${BgD}")` }}>
              <div className={"sliderContent"}>
                <h2 className={"title"}>LOG-TECH Konstrukcje</h2>
                <h3 className={"description"}>{T(["Wysoka jakość powłoki", "High quality coating", "Hochwertige Beschichtung"])}</h3>
                <div className=" d-flex pt-3">
                  <TopLink to={RoutePath[lang].WSPOLPRACA} className="mx-auto">
                    <Button variant="light">{T(["Współpraca", "Cooperation", "Zusammenarbeit"])}</Button>
                  </TopLink>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      {/* <div className="mobileOnly">
          <br />
          <br />
          <ItalicBeltBlock bgCol="light-gray" padding={256}>
            <div>Zapoznaj się z naszą nowoczesną Malarnią Proszkową.</div>
            <br />
            <LinkArrowBtn to={RoutePath[lang].PRODUKTY} text={"ZOBACZ PRODUKTY"} />
          </ItalicBeltBlock>
        </div> */}
      {/* </Container> */}
    </PageWrapper>
  );
};

export default HomePage;
