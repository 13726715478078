import React from "react";
import "./main-header.scss";

interface Props {
  children: React.ReactNode;
  align?: "left" | "center" | "right";
  bg?: string;
}

const MainHeader: React.FC<Props> = (props: Props) => {
  const { children, align, bg } = props;
  return (
    <>
      <div className={`main-header ${bg ? "--withBg" : ""}`} style={{ backgroundImage: `url("${bg}")` }}>
        <h1 className={`${align ? align : ""}`}>
          <span className="header-decoration">&nbsp;</span>
          {children}
        </h1>
      </div>
    </>
  );
};

export default MainHeader;
