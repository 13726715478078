import React from "react";
import "./nasza-historia-page.scss";
import { Helmet } from "react-helmet-async";
import MainHeader from "../../components/main-header/main-header";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import ArrowDown from "../../assets/arrowDownDark.svg";
import Col from "../../components/grid/col";
import StandardBlock from "../../components/standard-block/standard-block";
import TitleBlock from "../../components/title-block/title-block";
import BigNumBlock from "../../components/big-num-block/big-num-block";
import ItalicBeltBlock from "../../components/italic-belt-block/italic-belt-block";
import TopLink from "../../../app/components/top-link/top-link";
import { RoutePath } from "../../../route-paths";
import { Container, Row } from "codeebo-react-grid";
import BgImgBlock from "../../components/bgImg-block/bgImg-block";
import Button from "../../../libs/buttons/Button/Button";
import Grid from "../../components/grid/grid";
import { selectLang } from "../../../app/components/lang-switch/langSlice";
import { useAppSelector } from "../../../redux/store";
import T from "../../../app/components/lang-switch/t";

const NaszaHistoriaPage = () => {
  const lang = useAppSelector(selectLang);
  return (
    <PageWrapper className="nasza-historia-page">
      <Helmet>
        <title>{"LOG-TECH"}</title>
      </Helmet>

      <section>
        <Container>
          <MainHeader>{T(["O Firmie", "About us", "Über das Unternehmen"])}</MainHeader>
          <Grid>
            <br />
            <br />
            <br />
            <br />
            <Row>
              <Col size={6}>
                <StandardBlock>
                  <TitleBlock>{T(["Ropoczynamy działalność ...", "We are starting our business...", "Wir starten unser Geschäft..."])}</TitleBlock>
                  <br />
                  <br />
                  <p>
                    {T([
                      "Firma Log Tech istnieje na rynku od ponad 20 lat, specjalizuje się w produkcji pojemników specjalnego przeznaczenia i należy do czołówki europejskich firm specjalizujących się w tej dziedzinie",
                      "Log-Tech has been operating on the market for more than 20 years now. Our company specialises in the manufacture of special purpose containers and is one of the leading European companies specialising in this field. ",
                      "Log Tech ist seit mehr als 20 Jahren auf dem Markt, hat sich auf die Herstellung von Spezialbehältern spezialisiert und ist eines der führenden europäischen Fachunternehmen in diesem Bereich. ",
                    ])}
                  </p>
                </StandardBlock>
              </Col>
              <Col size={6}>
                <BigNumBlock>2002</BigNumBlock>
              </Col>
            </Row>
            <Row>
              <Col size={6}>
                <img className="arrowDown" src={ArrowDown} alt="down" />
              </Col>
              <Col size={6}>
                <BgImgBlock aspectRatio="3/2" img="/images/malarnia/logtech.png" />
              </Col>
            </Row>
            <Row>
              <Col size={6}>
                <br />
                <br />
                <StandardBlock>
                  <TitleBlock>{T(["Rozwój firmy", "Company growth", "Entwicklung des Unternehmens"])}</TitleBlock>
                  <br />
                  <br />
                  <p>
                    {T([
                      "Lakiernia proszkowa powstała w 2022 roku to kolejna inwestycja Właścicieli i kolejny etap poszerzenia działalności firmy Log-Tech.",
                      "Our powder paint shop, established in 2022, is a new investment of the company’s Owners and the next step in expanding Log-Tech’s operations. ",
                      "Die im Jahr 2022 errichtete Pulverbeschichtungsanlage ist die nächste Investition der Eigentümer und die nächste Etappe in der Erweiterung des Geschäfts von Log-Tech. ",
                    ])}
                  </p>
                </StandardBlock>
                <br />
                <br />
              </Col>
              <Col size={6}>
                <BigNumBlock>2022</BigNumBlock>
              </Col>
            </Row>

            <Row>
              <Col size={6}>
                <img className="arrowDown" src={ArrowDown} alt="down" />
              </Col>
              <Col size={6}>
                <BgImgBlock aspectRatio="3/2" img="/images/malarnia/logtech-rozwoj.png" />
              </Col>
            </Row>

            <Row>
              <Col size={6}>
                <br />
                <br />
                <StandardBlock>
                  <TitleBlock>{T(["Wysoka jakość", "High quality", "Hohe Qualität"])}</TitleBlock>
                  <br />
                  <br />
                  <p>
                    {T([
                      "W trosce o wysoka jakość naszych usług, najnowocześniejszą automatyczną lakiernią power&free kieruje zespół doświadczonych ludzi. Nasi pracownicy posiadają wyróżnienia i certyfikaty w zakresie malowania proszkowego",
                      "To ensure high quality services, our cutting-edge power-and-free automatic paint shop is managed by a team of experienced professionals. Our employees hold awards and certificates in the field of powder painting.",
                      "Um die hohe Qualität unserer Dienstleistungen zu gewährleisten, wird die hochmoderne automatische Power&Free-Lackiererei von einem Team erfahrener Mitarbeiter geleitet. Unsere Mitarbeiter verfügen über Auszeichnungen und Zertifikate im Bereich der Pulverbeschichtung.",
                    ])}
                  </p>
                </StandardBlock>
                <br />
                <br />
              </Col>
              <Col size={6}>
                <BigNumBlock></BigNumBlock>
              </Col>
            </Row>
            <Row>
              <Col size={6}>
                <img className="arrowDown" src={ArrowDown} alt="down" />
              </Col>
              <Col size={6}>
                <BgImgBlock aspectRatio="3/2" img="/images/malarnia/malarnia-proszkowa-manualna.png" />
              </Col>
            </Row>

            <Row>
              <Col size={6}>
                <br />
                <br />
                <StandardBlock>
                  <TitleBlock>{T(["Nowa linia", "New Line", "Neue Zeile"])}</TitleBlock>
                  <br />
                  <br />
                  <p>
                    {T([
                      "Nowa linia umożliwia powlekanie detali wykonanych z trzech różnych gatunków metali: Stal, Aluminium oraz stal ocynkowaną o wymiarach 1800x1800x5000 mm, o wadze maksymalnej 500kg.",
                      "Thanks to our new line, we can coat pieces made of three different metal grades: 1800 x 1800 x 5000 steel, aluminium and galvanised steel, with a maximum weight of 500 kg. ",
                      "Die neue Anlage ermöglicht die Beschichtung von Werkstücken, die aus drei verschiedenen Metallarten hergestellt sind: Stahl, Aluminium und verzinkter Stahl mit den Abmessungen von 1800 x 1800 x 5000, mit einem Höchstgewicht von 500 kg.",
                    ])}
                  </p>
                </StandardBlock>
                <br />
                <br />
              </Col>
              <Col size={6}>
                <BigNumBlock></BigNumBlock>
              </Col>
            </Row>
            <Row>
              <Col size={6}></Col>
              <Col size={6} className="actionItem py-5">
                <TopLink to={RoutePath[lang].KONTAKT} className="py-5">
                  <p className="welcome pb-3">{T(["Zapraszamy do kontaktu", "Feel free to contact us", "Kontaktiere uns"])}</p>
                  <img src="/images/logo.svg" alt="logo" />
                </TopLink>
              </Col>
            </Row>
          </Grid>
          <ItalicBeltBlock>{T(["Nasza historia dalej się tworzy...", "Our history keeps on making ...", "Unsere Geschichte wird weitergeschrieben..."])}</ItalicBeltBlock>
        </Container>
      </section>
      <section className="primaryBg">
        <Container>
          <Grid>
            <Row className="py-3">
              <Col size={8} className="py-2">
                <StandardBlock>
                  <TitleBlock>{T(["Rozpocznij współpracę z naszą Malarnią Proszkową", "We encourage you to partner with our Powder Paint Shop ", "Beginnen Sie eine Zusammenarbeit mit unserer Pulverbeschichtungsanlage "])}</TitleBlock>
                </StandardBlock>
              </Col>
              <Col size={4} className="py-2">
                <TopLink to={RoutePath[lang].KONTAKT}>
                  <Button variant="light">{T(["Skontaktuj się", "Feel free to contact us", "Kontaktieren Sie uns "])}</Button>
                </TopLink>
              </Col>
            </Row>
          </Grid>
        </Container>
      </section>
    </PageWrapper>
  );
};

export default NaszaHistoriaPage;
