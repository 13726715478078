import { AvaliableLangs } from "../app/components/lang-switch/langSlice";

export function findLangByLocation(pathname?: string): AvaliableLangs {
  if (pathname) {
    if (pathname.toLowerCase().indexOf("/en") !== -1) return "EN";
    if (pathname.toLowerCase().indexOf("/de") !== -1) return "DE";
    else return "PL";
  }
  return "PL";
}
