import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, useAppSelector } from "../../../redux/store";
import { useDispatch } from "react-redux";

export interface CookiePolicyPopupState {
  is_cookiePolicyPopupOpen: Boolean;
}

const initialState: CookiePolicyPopupState = {
  is_cookiePolicyPopupOpen: true,
};

export const cookiePolicyPopupSlice = createSlice({
  name: "cookiePolicyPopup",
  initialState,
  reducers: {
    set_is_cookiePolicyPopupOpen: (state, action: PayloadAction<boolean>) => {
      state.is_cookiePolicyPopupOpen = action.payload;
    },
  },
});

export const { set_is_cookiePolicyPopupOpen } = cookiePolicyPopupSlice.actions;
export default cookiePolicyPopupSlice;

export function useCookiePolicyPopup() {
  const isOpen = useAppSelector((state: RootState) => state.cookiePolicyPopup.is_cookiePolicyPopupOpen);

  const dispatch = useDispatch();

  const setOpen = (isOpen: boolean) => dispatch<any>(cookiePolicyPopupSlice.actions.set_is_cookiePolicyPopupOpen(isOpen));

  return { setOpen, isOpen };
}
