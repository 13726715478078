import React, { ReactNode, useRef } from "react";
import "./navbar.scss";
import { RoutePath } from "../../../route-paths";
import MenuBtnIcon from "../../assets/menuBtn.svg";
import CloseBtnIcon from "../../assets/closeBtn.svg";
import TopLink from "../../../app/components/top-link/top-link";
import { useNavbar } from "./navbarSlice";
import { Col, Container, Row } from "codeebo-react-grid";
import LangSwitch from "../../../app/components/lang-switch/lang-switch";
import { useAppSelector } from "../../../redux/store";
import { selectLang } from "../../../app/components/lang-switch/langSlice";
import T from "../../../app/components/lang-switch/t";

interface Props {
  isSubNavHidden?: boolean;
  children?: ReactNode;
}

const Navbar: React.FC<Props> = (props: Props) => {
  const firstLink = useRef<any>();
  const { setOpen, isActive, isOpen } = useNavbar();
  const lang = useAppSelector(selectLang);

  const setNavExpanded = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  const links = () => {
    return (
      <>
        <div className="link">
          <TopLink to={RoutePath[lang].OFIRMIE}>{T(["O Firmie", "About Company", "Über das Unternehmen"])}</TopLink>
        </div>
        <div className="link">
          <TopLink to={RoutePath[lang].MALOWANIE_PROSZKOWE}>{T(["Malarnia Proszkowa", "Powder Painting", "Pulverbeschichtungsanlage"])}</TopLink>
        </div>
        <div className="link">
          <TopLink to={RoutePath[lang].TECHNOLOGIA}>{T(["Technologia", "Technology", "Technologie"])}</TopLink>
        </div>
        <div className="link">
          <TopLink to={RoutePath[lang].WSPOLPRACA}>{T(["Współpraca", "Cooperation", "Zusammenarbeit"])}</TopLink>
        </div>
        <div className="link">
          <TopLink to={RoutePath[lang].KONTAKT}>{T(["Kontakt", "Contact", "Kontakt"])}</TopLink>
        </div>
        <hr />
        <div className="link">
          <LangSwitch />
        </div>
      </>
    );
  };

  return (
    <>
      <div className={`navbarBarWrapper`}>
        <div className={`navbarBar`}>
          <Container>
            <Row align="center">
              <Col size={[3, 3, 3, 6]}>
                <div className={`navbarLogo`} onClick={() => setNavExpanded(false)}>
                  <TopLink to={RoutePath[lang].HOME}>
                    <img src="/images/logo.svg" alt="LogoImg" />
                  </TopLink>
                </div>
              </Col>
              <Col size={[9, 9, 9, 6]} className="text-right">
                <div className="hide-on-mobile links">{links()}</div>
                <div
                  className={`show-on-mobile menuBtn${isOpen ? " isActive" : ""}${isActive ? " navbarbarActive" : ""}`}
                  onClick={() => setNavExpanded(!isOpen)}
                >
                  {isOpen ? <img src={CloseBtnIcon} className="closeBtn" alt="" /> : <img src={MenuBtnIcon} alt="" />}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="mobileMenuWrapper">
          <div className={`mobileSideMenuOverlay ${isOpen ? "isActive" : ""}`} onClick={() => setNavExpanded(false)}></div>
          <div className={`mobileSideMenu ${isOpen ? "isActive" : ""}`}>
            <div className={`mobileSideMenuInsetWrapper isScrolled`} onClick={() => setNavExpanded(false)} ref={firstLink}>
              {links()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
