/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import "./footer.scss";
import IdeeasLogo from "./../../assets/ideeas.svg";
import { Col, Container, Row } from "codeebo-react-grid";

interface Props {}

const Footer: React.FC<Props> = () => {
  return (
    <>
      <section className="footer">
        <Container>
          <Row>
            <Col size={[4, 4, 6, 12]}>© Log-Tech Konstrukcje Sp. z o.o. Sp. K 2024</Col>
            <Col size={[4, 4, 6, 12]} className="center">
              <a href="https://ideeas.pl">
                <img src={IdeeasLogo} alt="" />
              </a>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Footer;
