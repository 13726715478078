import "./App.scss";

import { RoutePath } from "../route-paths";
import { Route, Routes, useLocation } from "react-router-dom";
import RouterTransition from "./components/router-transition/router-transition";
import Navbar from "../framework/components/navbar/navbar";
import { useNavbar } from "../framework/components/navbar/navbarSlice";
import PreFooter from "../framework/components/prefooter/prefooter";
import Footer from "../framework/components/footer/footer";
import CookiePolicyPopup from "../framework/components/cookie-policy-popup/cookie-policy-popup";
import WspolpracaPage from "../framework/pages/wspolpraca-page/wspolpraca-page";
import MalowanieProszkowePage from "../framework/pages/malowanie-proszkowe/malowanie-proszkowe";
import TechnologiaPage from "../framework/pages/technologia/technologia";
import KontaktPage from "../framework/pages/kontakt-page/kontakt-page";
import NaszaHistoriaPage from "../framework/pages/nasza-historia-page/nasza-historia-page";
import HomePage from "../framework/pages/home-page/home-page";
import { AvaliableLangs, selectLang, set } from "./components/lang-switch/langSlice";
import { findLangByLocation } from "../features/findLangByLocation";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/store";

function App() {
  const { isOpen } = useNavbar();
  const dispatch = useAppDispatch();
  const lang = useAppSelector(selectLang);
  const location = useLocation();
  const urlLang = findLangByLocation(location.pathname);
  useEffect(() => {
    if (urlLang !== lang) {
      dispatch(set(urlLang));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const renderRoutesWithLang = (lang: AvaliableLangs) => {
    return (
      <>
        <Route path={RoutePath[lang].WSPOLPRACA} element={<WspolpracaPage />} />
        <Route path={RoutePath[lang].MALOWANIE_PROSZKOWE} element={<MalowanieProszkowePage />} />
        <Route path={RoutePath[lang].TECHNOLOGIA} element={<TechnologiaPage />} />
        <Route path={RoutePath[lang].KONTAKT} element={<KontaktPage />} />
        <Route path={RoutePath[lang].OFIRMIE} element={<NaszaHistoriaPage />} />
        {/* <Route path={RoutePath.RULES} element={<RulesPage />} /> */}
        <Route path={RoutePath[lang].HOME} element={<HomePage />} />
        {/* <Route path={"*"} element={<NotFoundPage />} /> */}
      </>
    );
  };

  return (
    <div id="app">
      <RouterTransition />
      <Navbar />
      <div className={`overlay${isOpen ? " isActive" : ""}`}>
        <div className="routes-wrapper">
          <Routes>
            {renderRoutesWithLang("EN")}
            {renderRoutesWithLang("DE")}
            {renderRoutesWithLang("PL")}
          </Routes>
        </div>
        <PreFooter />
        <Footer />
      </div>
      <CookiePolicyPopup />
    </div>
  );
}

export default App;
