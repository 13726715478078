import React from "react";
import "./grid.scss";

interface Props {
  children?: React.ReactNode,
  size: 3 | 4 | 5 | 6 | 8 | 12 | 20,
  className?: string,
  offset?: 1
}

const Col: React.FC<Props> = (props: Props) => {
  return (
    <div className={`col col-${props.size} ${props.offset ? "col-offset-" + props.offset : ""} ${props.className ? props.className : ""}`}>
      {props.children ? props.children : null}
    </div>
  )
}

export default Col;