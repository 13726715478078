import React from "react";
import Col from "../../components/grid/col";
import Grid from "../../components/grid/grid";
import Row from "../../components/grid/row";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import StandardBlock from "../../components/standard-block/standard-block";
import TitleBlock from "../../components/title-block/title-block";
// import LogoImg from "../../assets/logo.png";
import "./wspolpraca-page.scss";
import { Helmet } from "react-helmet-async";
import Button from "../../../libs/buttons/Button/Button";
import TopLink from "../../../app/components/top-link/top-link";
import { RoutePath } from "../../../route-paths";
import MainHeader from "../../components/main-header/main-header";
import { Container } from "codeebo-react-grid";
import BgImgBlock, { BgYTBlock } from "../../components/bgImg-block/bgImg-block";
import { selectLang } from "../../../app/components/lang-switch/langSlice";
import { useAppSelector } from "../../../redux/store";
import T from "../../../app/components/lang-switch/t";

const WspolpracaPage = () => {
  const lang = useAppSelector(selectLang);
  return (
    <PageWrapper className="wspolpraca-page">
      <Helmet>
        <title>{"LOG-TECH"}</title>
      </Helmet>
      <section>
        <Container>
          <MainHeader>
            {T([
              "Rozpocznij z nami współpracę i zaufaj sprawdzonym procesom",
              "Start working with us and trust our tried and tested processes",
              "Beginnen Sie die Zusammenarbeit mit uns und vertrauen Sie auf bewährte Prozesse.",
            ])}
          </MainHeader>
          <Grid>
            <Row>
              <Col size={6}>
                <StandardBlock>
                  <br />
                  <TitleBlock>{T(["MALARNIA PROSZKOWA LOG-TECH", "LOG-TECH’S POWDER PAINT SHOP", "PULVERBESCHICHTUNGSANLAGE VON LOG-TECH "])}</TitleBlock>
                  <br />
                  <br />
                  <p className="paddingRight">
                    {T([
                      "Co nas wyróżnia? Indywidualne podejście do każdej rozpoczynanej współpracy, które pozwala nam na kontynuowanie jej przez długi okres czasu.",
                      "What makes us special? An individual approach to each cooperation we start, so that we can continue it long term. We make each and every customer feel special and confident that everything we do is done with great care. Having our customers' best interests at heart, we have developed a number of processes to ensure that the pieces we paint are of high quality at all times.",
                      "Was zeichnet uns aus? Eine individuelle Herangehensweise an jede von uns begonnene Zusammenarbeit, die es uns ermöglicht, sie langfristig fortzusetzen. Bei uns kann sich jeder Kunde, jeder Auftraggeber, gut aufgehoben fühlen und sicher sein, dass alle Arbeiten mit der gebotenen Sorgfalt ausgeführt werden. Zum Nutzen unserer Kunden haben wir eine Reihe von Verfahren entwickelt, die sicherstellen, dass die lackierten Werkstücke stets von hoher Qualität sind.",
                    ])}
                  </p>
                </StandardBlock>
                <br />
                <br />
                <TopLink to={RoutePath[lang].MALOWANIE_PROSZKOWE}>
                  <Button variant="outline">{T(["O Malarni Proszkowej", "LOG-TECH’S POWDER PAINT SHOP", "PULVERBESCHICHTUNGSANLAGE VON LOG-TECH "])}</Button>
                </TopLink>
              </Col>
              <Col size={6} className="py-md-2 py-3">
                <BgYTBlock src="https://www.youtube-nocookie.com/embed/cf_zj4d-EVg" aspectRatio="560/315" />
                {/* <BgImgBlock aspectRatio="3/2" img={"/images/3.png"} /> */}
              </Col>
            </Row>
          </Grid>
        </Container>
      </section>

      <section className="lightGrayBg py-md-5 py-3">
        <Container>
          <Grid>
            <Row>
              <Col size={6}>
                <StandardBlock>
                  <br />
                  <TitleBlock>{T(["WYSOKA JAKOŚĆ MALOWANEJ POWŁOKI", "High quality coating", "Hohe Qualität der Lackbeschichtung"])}</TitleBlock>
                  <br />
                  <br />
                  <p className="paddingRight">
                    {T([
                      "Elementy malowane proszkowo zapewniają większą odporność na uszkodzenia mechaniczne oraz doskonałą ochronę antykorozyjną. W pełni zautomatyzowane procesy gwarantują szybkie terminy realizacji i pomagają nam optymalizować koszty. Malowanie proszkowe to idealne rozwiązanie, jeśli zależy nam na uzyskaniu różnorodnych struktur powłokowych i ładnego wykończenia. Można wybierać z szerokiej gamy kolorów RAL z oferty malowania proszkowego LOG-TECH Konstrukcje.",
                      "Powder-painted components offer higher resistance to mechanical damage and excellent anti-corrosion protection. Fully automated processes guarantee fast lead times and help us to optimise the costs. Powder painting is a perfect solution if you want to get a variety of coating structures and nice-looking finish. You can choose from a wide range of RAL colours from the LOG-TECH Konstrukcje powder painting offer.",
                      "Pulverbeschichtete Werkstücke sind widerstandsfähiger gegen mechanische Beschädigungen und bieten einen hervorragenden Schutz gegen Korrosion. Vollautomatisierte Prozesse garantieren kurze Fertigungszeiten und optimieren die Kosten. Die Pulverbeschichtung ist eine hervorragende Lösung, wenn wir verschiedene Beschichtungsstrukturen und deren ästhetisches Aussehen erreichen wollen. Bei dem Angebot zur Pulverbeschichtung von LOG-TECH Konstrukcje kann man aus einer breiten Palette von RAL-Farben wählen.",
                    ])}
                  </p>
                </StandardBlock>
              </Col>
              <Col size={6} className="py-md-2 py-3">
                <BgImgBlock aspectRatio="3/2" img="/images/w2.jpg" />
              </Col>
            </Row>
          </Grid>
        </Container>
      </section>
      <section className="py-md-5 py-3">
        <Container>
          <Grid>
            <Row>
              <Col size={6}>
                <StandardBlock>
                  <br />
                  <TitleBlock>
                    {T([
                      "ROZPOCZNIJ Z NAMI WSPÓŁPRACĘ JUŻ TERAZ",
                      "DON’T HESITATE TO PARTNER WITH US TODAY",
                      "BEGINNEN SIE DIE ZUSAMMENARBEIT MIT UNS SCHON JETZT",
                    ])}
                  </TitleBlock>
                  <br />
                  <br />
                  <p className="paddingRight">
                    {T([
                      "Jesteś zainteresowany wysokiej jakości powłoką powierzchni? Z przyjemnością omówimy z Państwem wszystkie możliwości i przedstawimy Państwu naszą ofertę!",
                      "Are you interested in high-quality surface coating? We would be happy to discuss all the possibilities with you and present you with our offer!",
                      "Sind Sie an einer hochwertigen Oberflächenbeschichtung interessiert? Gerne besprechen wir mit Ihnen alle Möglichkeiten und präsentieren Ihnen unser Angebot!",
                    ])}
                  </p>
                </StandardBlock>
                <br />
                <br />
                <TopLink to={RoutePath[lang].KONTAKT}>
                  <Button variant="outline">{T(["Skontaktuj się z nami", "Contact us", "PKontaktieren Sie uns"])}</Button>
                </TopLink>
              </Col>
              <Col size={6} className="py-md-2 py-3">
                <BgImgBlock aspectRatio="3/2" img={"/images/logtech-malarnia-proszkowa-sycow.png"} />
              </Col>
            </Row>
          </Grid>
        </Container>
      </section>
    </PageWrapper>
  );
};

export default WspolpracaPage;
